:root {
  font-size: 16px;
  --font-family-1: 'Libre Baskerville', serif;
  --font-family-2: 'Nunito Sans', sans-serif;
  --animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1)
}

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  background-color: #fafafa;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  direction: rtl;
  /* update text-align value to right;  on latin screens*/
  direction: ltr;
  /* update text-align value to left; on latin screens*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.sb-show-main.sb-main-padded {
  padding: 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

a {
  background-color: transparent;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  display: block;
  border: none;
  outline: none;
  max-width: 100%;
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  /* 2 */
  outline: none;
}

input,
button,
textarea,
select {
  font-family: inherit;
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:hover {
  cursor: pointer;
  animation: jelly 0.5s;
}

@keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
// button:-moz-focusring,
// [type="button"]:-moz-focusring,
// [type="reset"]:-moz-focusring,
// [type="submit"]:-moz-focusring {
//   outline: 1px dotted ButtonText;
// }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"],
[type="date"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */

  &::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
}

/* Remove list styles on ul, ol */
ul,
ol,
li {
  list-style: none;
  padding: 0;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

button:focus,
button:hover {
  outline: none;
}
