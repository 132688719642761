@use "../../styles/abstract/_mixins.scss" as *;
@use "../../styles/abstract/_variables.scss" as *;

.main-cursor {
	z-index: 1000;
	position: fixed;
	border-radius: 50%;
	overflow: hidden;
	width: 6px;
	height: 6px;
	pointer-events: none;
	mix-blend-mode: difference;
	background-color: #fff;
	display: none;

	@include respond(tab) {
		display: none;
	}
}

.secondary-cursor {
	z-index: 1000;
	position: fixed;
	border-radius: 50%;
	overflow: hidden;
	width: 12px;
	height: 12px;
	pointer-events: none;
	mix-blend-mode: difference;
	background-color: #fff;
	display: block;
	will-change: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Product-sans, sans-serif";
	font-style: normal;
	will-change: width, height;
	transform-origin: center;
	font-weight: 400;
	transition: cubic-bezier(0.79, 0.14, 0.15, 0.86);

	@include respond(tab) {
		display: none;
	}
}

.enlarged {
	width: 100px;
	height: 100px;
}
