//Fonts
@use "./fonts/kaftan.scss";
@use "./fonts/product-sans.scss";
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap");

#e6pPnNAsrUC10_to {
	animation: e6pPnNAsrUC10_to__to 1500ms linear 1 normal forwards;
}
@keyframes e6pPnNAsrUC10_to__to {
	0% {
		transform: translate(337.958488px, 46.484px);
	}
	6.666667% {
		transform: translate(337.958488px, 46.484px);
		animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	}
	80% {
		transform: translate(337.958488px, 14.143904px);
	}
	100% {
		transform: translate(337.958488px, 14.143904px);
	}
}
#e6pPnNAsrUC10 {
	animation: e6pPnNAsrUC10_c_o 1500ms linear 1 normal forwards;
}
@keyframes e6pPnNAsrUC10_c_o {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	46.666667% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
