/* Product-sans */
@font-face {
	font-family: "Product-sans, sans-serif";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		url("../../assets/fonts/Product-sans/Product-Sans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Product-sans, sans-serif";
	font-style: italic;
	font-weight: 400;
	src: local(""),
		url("../../assets/fonts/Product-sans/Product-Sans-Italic.ttf") format("truetype");
}

@font-face {
	font-family: "Product-sans, sans-serif";
	font-style: normal;
	font-weight: 700;
	src: local(""),
		url("../../assets/fonts/Product-sans/Product-Sans-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Product-sans, sans-serif";
	font-style: italic;
	font-weight: 700;
	src: local(""),
		url("../../assets/fonts/Product-sans/Product-Sans-Bold-Italic.ttf") format("truetype");
}
